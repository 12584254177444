.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 100%;
  max-width: 408px;
  padding: 8px;
}

.logo-link {
  text-decoration: underline;
  margin-top: 96px;
  margin-bottom: 96px;
  display: block;
}

button {
  padding: 24px 42px;
  border-radius: 42px;
  background-color: #62BA46;
  color: white;
  border: none;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  margin: 16px 0;
}

button.no-margin-top {
  margin-top: 0;
}

button.borderless {
  background-color: transparent;
  color: #111;
  font-size: 20px;
  padding: 12px 28px;
}

button.borderless.marginless {
  margin: 0;
  padding: 0 0 0 16px;
}

.Practice .entry {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
}

.Practice .entry input {
  margin-top: 36px;
}

input {
  border: 5px solid #BAC1C7;
  font-size: 24px;
  padding: 8px;
  font-weight: bold;
}

button:active {
  opacity: .9;
}

.speaker-img img {
  width: 84px;
}

.close-img img {
  width: 48px;
}

.trash-img {
  width: 48px;
}

.red {
  color: red;
}

.wordlist-field {
  display: flex;
  margin: 12px 0;
}

.Words {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wordlist-field input {
  flex-grow: 0;
  flex-shrink: 0;
  height: 64px;
  width: 275px;
}

.correction {
  font-family: sans-serif;
  font-weight: bold;
  margin: 4px 0;
  font-size: 28px;
}

.margin-top-48 {
  margin-top: 48px;
}

.back-arrow {
  width: 24px;
  position: relative;
  top: 2px;
}

.Finished {
  margin-top: 96px;
}

.checker {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: 48px;
}

input:focus {
  outline: none;
  border-color: #afb6bd;
};


